import React from 'react';

const MastHead = () => (
  <header className='masthead'>
    <div className='container'>
      <div className='masthead-subheading'>Welcome To Global Recovery </div>
      <div className='masthead-heading text-uppercase'>
        It's Nice To Meet You
      </div>
      <a
        className='btn btn-primary btn-xl text-uppercase js-scroll-trigger'
        href='#services'
      >
        Tell Me More
      </a>
    </div>
  </header>
);

export default MastHead;
